import request from '@/utils/request'

export function getElecate() {
    return request({
        url: '/api.Classify/getElecate',
        method: 'get',
        params: {}
    })
}

export function getPackCate() {
    return request({
        url: '/api.Classify/getPackCate',
        method: 'get',
        params: {}
    })
}

export function getPackages(query) {
    return request({
        url: '/admin.api.Basic/getPackages',
        method: 'get',
        params: query
    })
}

export function getElements(query) {
    return request({
        url: '/admin.api.Basic/getElements',
        method: 'get',
        params: query
    })
}

export function getPaper(data) {
    return request({
        url: '/admin.api.Exam/getPaper',
        method: 'post',
        data
    })
}









export function saveUserTrain(data) {
    return request({
        url: '/api.Exam/getPaper',
        method: 'post',
        data
    })
}


export function login(data) {
    return request({
        url: '/fore/login/index',
        method: 'post',
        data
    })
}

export function signup(data) {
    return request({
        url: '/icao/login/reg',
        method: 'post',
        data
    })
}


export function UpdatePassword(data) {
    return request({
        url: '/icao/Login/changePwd',
        method: 'post',
        data
    })
}

export function getInfo(token) {
    return request({
        url: '/icao/user/info',
        method: 'get',
        params: { token }
    })
}

export function logout() {
    return request({
        url: '/icao/user/logout',
        method: 'post'
    })
}