<template>
  <div class="home">
    
    欢迎来到航科在线安检培训考核系统！
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  /* eslint-disable */
  name: 'Home',
  components: {
  }
}
</script>
